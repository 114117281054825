import React from 'react';
import PropTypes from 'prop-types';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/styles';

import Logo from 'images/Hayfield-Ident-Reverse.svg';
import { latLng2Tile } from 'google-map-react';

const styles = theme => ({
  slider: {
    '&.awssld': {
      ' --loader-bar-color': '#1f1b37',
    },
    '& .awssld__controls button span': {
      '&:before, &:after': {
        backgroundColor: theme.palette.text.white,
      },
    },
    '& .awssld__bullets': {
      bottom: '40px',
      zIndex: 2,
      '& button': {
        backgroundColor: theme.palette.text.white,
        width: '10px',
        height: '10px',
        [theme.breakpoints.up('lg')]: {
          width: '16px',
          height: '16px',
        },
      },
    },
    '& .awssld__content': {
      alignItems: 'normal',
    },
  },
  textWrapper: {
    zIndex: '10',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    width: '80%',
    margin: '0 auto',
  },
  ident: {
    width: '20px',
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      width: '50px',
      marginTop: '40px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      letterSpacing: 'normal',
    },
  },
});

function ImageSlider(props) {
  const { data, classes } = props;
  const getSlides = () => {
    return data.map((item, key) => {
      if (!item.image) return <div key={key}></div>;
      return (
        <div key={key} data-src={item.image.url}>
          {item.title && item.title[0].text && (
            <div className={classes.textWrapper}>
              <Box>
                <Logo className={classes.ident} />
              </Box>
              <Box width="50">
                <Typography
                  component="p"
                  variant="h2"
                  color="secondary"
                  align="center"
                  className={classes.title}
                >
                  {item.title[0].text}
                </Typography>
              </Box>
              <Box visibility="hidden">
                <Logo className={classes.ident} />
              </Box>
            </div>
          )}
        </div>
      );
    });
  };
  return (
    <AwesomeSlider className={classes.slider} bullets={false}>
      {getSlides()}
    </AwesomeSlider>
  );
}

ImageSlider.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageSlider);
